@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
body {
    font-family: 'Roboto', sans-serif;
  } */

@layer utilities {
    .rtl {
        direction: rtl;
    }
}



.fontBold {
    font-weight: 700;
}

.fontExtraBold {
    font-weight: 900;
}

.bgGradient {
    background: linear-gradient(90deg, rgba(59,130,246,1) 0%, rgba(16,185,129,1) 100%);
}

.spaceY-4 {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
}

.roundedFull {
    border-radius: 9999px;
}

.textGradient {
    background-clip: text;
    text-fill-color: transparent;
}

.leadingTight {
    line-height: 1.25;
}

.textCenter {
    text-align: center;
}

.italic {
    font-style: italic;
}

.flex {
    display: flex;
}

.justifyCenter {
    justify-content: center;
}

.itemsCenter {
    align-items: center;
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.p-8 {
    padding: 2rem;
}

.roundedLg {
    border-radius: 0.5rem;
}
.video-container {
    width: 100%;
  }
  
  .video-item {
    width: 100%;
    height: auto;
  }
  
  /* For large screens, you can adjust the video width and height */
  @media (min-width: 1024px) {
    .video-item {
      width: 320px;
      height: 568px; /* This is the height of TikTok videos, you can adjust if needed */
    }
  }
  


/* styles.css */

.sticky {
    position: sticky;
    top: 0;
    z-index: 10; /* Ensure it stays on top of other content */
}

section.bg-gray-50 {
    background-color: #F3F4F6; /* Slight variation to the gray color for modern feel */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0,0,0,0.1); /* Subtle shadow */
}

section.bg-gray-50 a {
    color: #6366F1; /* Choosing an indigo-ish color for links */
    text-decoration: none; /* Removing underline */
    transition: color 0.3s ease; /* Smooth color transition */
}

section.bg-gray-50 a:hover {
    color: #4338CA; /* Slightly darker indigo for hover */
}

section.bg-gray-50 ol {
    list-style-type: none;
    padding-left: 0;
}

section.bg-gray-50 li {
    padding: 8px 0; /* Consistent spacing between list items */
}

section.bg-gray-50 h2 {
    border-bottom: 2px solid #E5E7EB; /* Light border for separation */
    padding-bottom: 16px;
    margin-bottom: 16px;
}


@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .highlight {
    background-color: #ffeb3b; /* Yellow background */
    padding: 0.25rem;
    border-radius: 0.375rem;
  }
  


  
  
  @keyframes shimmer {
    0% { background-position: 0% 50%; }
    50% { background-position: 150% 50%; }
    100% { background-position: 0% 50%; }
  }

  .animate-shimmer {
    animation: shimmer 8s ease infinite;
  }

  .bg-300\% {
    background-size: 300% 100%;
  }

  .animate-fadeIn {
    animation: fadeIn 0.8s ease forwards;
  }

  .animate-fadeInUp {
    animation: fadeInUp 0.8s ease forwards;
  }

  .animate-pulse-slow {
    animation: pulse 3s ease-in-out infinite;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  
